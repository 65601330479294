import React, { useState, useEffect } from "react";
import mac1 from "../assets/products/mac1.png";
import swal from "sweetalert";
import "../components/cart.css";
import { auth, db, app, firestore } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { set, ref, onValue } from "firebase/database";
import { uid } from "uid";
import Products from "../components/Products";
import { QuantityPicker } from "react-qty-picker";
import Bill from "./Bill";

function Cart() {
  var date = new Date();
  let Time = date.getTime().toString;
  var Dated =  date.getDate() + "/" + (date.getMonth()+1)  + "/" + date.getFullYear();
 
  let stock = 9;

  const [CustomerID, setCustomerID] = useState("");
  const [All, setAll] = useState([]);
  const [CompanyName, setCompanyName] = useState("");
  const [InvoiceNumber, setInvoiceNumber] = useState("");
  const [DistributorName, setDistributorName] = useState("");
  const [Phone, setPhone] = useState("");
  const [BillingAddress, setBillingAddress] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [District, setDistrict] = useState("");
  const [State, setState] = useState("");
  const [BDate, setBDate] = useState("");
  const [PIN, setPIN] = useState("");
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [X450, setX450] = useState(0);
  const [W140, setW140] = useState(0);
  const [X900, setX900] = useState(0);
  const [W300, setW300] = useState(0);
  const [X450Total, setX450Total] = useState(0);
  const [X900Total, setX900Total] = useState(0);
  const [Price1, setPrice1] = useState(0);
  const [PriceW140, setPriceW140] = useState(0);
  const [PriceX900, setPriceX900] = useState(0);
  const [PriceW300, setPriceW300] = useState(0);
  const [W140Total, setW140Total] = useState(0);
  const [W300Total, setW300Total] = useState(0);
  const [machines, setmachines] = useState(0);
  const [CGSTW140, setCGSTW140] = useState(0);
  const [SGSTW140, setSGSTW140] = useState(0);
  const [CGSTW300, setCGSTW300] = useState(0);
  const [SGSTW300, setSGSTW300] = useState(0);
  const [CGSTX900, setCGSTX900] = useState(0);
  const [SGSTX900, setSGSTX900] = useState(0);
  const [Total, setTotal] = useState();

  var X450count = 0, 
  X900count=0,
    W140count = 0,
    W300count=0;

  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  const writeToDatabase = () => {
    const uidd = uid();
    set(ref(db, `/Purchases/${auth.currentUser.uid}/${uidd}`), {
      CustomerID: CustomerID,
      CompanyName: CompanyName,
      InvoiceNumber: InvoiceNumber,
      Date: Dated,
      DistributorName: DistributorName,
      Phone: Phone,
      BillingAddress: BillingAddress,
      District: District,
      State: State,
      PIN: PIN,
      GSTNumber: GSTNumber,
      X450: X450,
      W140: W140,
      W140Total: W140Total,
      X450Total: X450Total,
      X900Total :X900Total,
      W300Total:W300Total,
      CGST: CGST,
      SGST: SGST,
      SGSTW140: SGSTW140,
      SGSTW300: SGSTW300,
      CGSTW300: CGSTW300,
      CGSTX900 : CGSTX900,
      CGSTW140: CGSTW140, 
      SGSTX900 : SGSTX900,

      Total: W140Total + X450Total + X900Total + W300Total,
    });
    // setCustomerID("");
    // setCompanyName("");
    // setInvoiceNumber("");
    // setDistributorName("");
    // setPIN("");
    // setPhone("");
    // setBillingAddress("");
    // setDistrict("");
    // setGSTNumber("");
    // setState("");
  };

  //var Total = W140Total+X450Total;
  const handleSubmit = (e) => {
    e.preventDefault();
    setTotal(W140Total + X450Total);
    setCustomerID("001");
    setGSTNumber("ABC123123123");
    setCompanyName("SprayX");
    setInvoiceNumber(7);
    setDistributorName("Praveen Daas");
    setPhone(9987654321);
    setBillingAddress("No.20, Race Course reload, R.S.Puram, Coimbatore");
    setDistrict("Coimbatore");
    setState("Tamil Nadu");
    setPIN(641018);
    // setBDate(BDate);
    writeToDatabase();
    // console.log(name,email,phone);
    const data = {
      CustomerID,
      DistributorName,
      Phone,
      PIN,
      InvoiceNumber,
      District,
      CompanyName,
      BillingAddress,
      GSTNumber,
      State,
      Dated,
      Time,
      X450, X450Total, W140Total, X900Total, W300Total,
      W140,X900,W300,
      CGST,SGST,
      CGSTW140, SGSTW140, CGSTW300, SGSTW300, CGSTX900, SGSTX900,
      
      X450Total,
      W140Total, X900Total, W300Total,
    };

      fetch("https://sheet.best/api/sheets/3b29ffd0-a7bb-4a24-9f2e-b88e413bb41e", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json())
      .then((data) => {
        // The response comes here

        //setCustomerID('');
        // setDistributorName('');
        // setPhone('');
        // setPIN('');
        // setBillingAddress('');
        // setDistrict('');
        // setState('');
        // setCompanyName('');
        // setInvoiceNumber('');
        // setGSTNumber('');

      })

      .catch((error) => {
        // Errors are reported there
        console.log(error);
      });
  };

  const [Forward, setForward] = useState({
    CustomerID: CustomerID,
    CompanyName: CompanyName,
    InvoiceNumber: InvoiceNumber,
    Date: Dated,
    DistributorName: DistributorName,
    Phone: Phone,
    BillingAddress: BillingAddress,
    District: District,
    State: State,
    PIN: PIN,
    GSTNumber: GSTNumber,
    X450: X450,
    W140: W140,
    W300:W300,
    X900:X900,
    W140Total: W140Total,
    X450Total: X450Total,
    X900Total :X900Total,
    W300Total:W300Total,
    CGST: CGST,
    SGST: SGST,
    SGSTW140: SGSTW140,
    SGSTW300: SGSTW300,
    CGSTW300: CGSTW300,
    CGSTX900 : CGSTX900,
    CGSTW140: CGSTW140, 
    SGSTX900 : SGSTX900,
    Total: W140Total + X450Total + X900Total + W300Total,
  });

  return (
    <div id="form_bg">
      <h1>INVOICE FORM</h1>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <div id="conrow">
          <div>
            <div>
              Customer ID<span id="red_star">*</span>
            </div>
            <input
              id="input"
              type="text"
              
              required
              autoComplete="off"
              placeholder="Customer ID"
              onChange={(e) => setCustomerID(e.target.value)}
              value={CustomerID}
            />
          </div>

          <div>
            <div>
              GST Number<span id="red_star">*</span>
            </div>
            <input
              id="input"
              type="text"
              
              required
              autoComplete="off"
              placeholder="GST Number"
              onChange={(e) => setGSTNumber(e.target.value)}
              value={GSTNumber}
            />
          </div>

          <div>
            <div>
              Company Name <span id="red_star">*</span>
            </div>
            <input
              id="input"
              type="text"
              
              required
              autoComplete="off"
              placeholder="Customer ID"
              onChange={(e) => setCompanyName(e.target.value)}
              value={CompanyName}
            />
          </div>

          <div>
            <div>
              Invoice Number<span id="red_star">*</span>
            </div>
            <input
              id="input"
              placeholder="Last Name"
              
              type="text"
              autoComplete="off"
              required
              onChange={(e) => setInvoiceNumber(e.target.value)}
              value={InvoiceNumber}
            />
          </div>
        </div>

        <div id="conrow">
          <div>
            <div>
              Distributor Name<span id="red_star">*</span>
            </div>
            <div>
              <input
                id="input"
                placeholder="Last Name"
                
                type="text"
                autoComplete="off"
                required
                onChange={(e) => setDistributorName(e.target.value)}
                value={DistributorName}
              />
            </div>{" "}
          </div>

          <div>
            <div>
              Contact Number <span id="red_star">*</span>
            </div>
            <div>
              <input
                id="input"
                required
                
                type="number"
                placeholder="99876 54321"
                onChange={(e) => setPhone(e.target.value)}
                value={Phone}
              />{" "}
            </div>{" "}
          </div>

          <div>
            <div>
              Billing Address<span id="red_star">*</span>
            </div>

            <input
              id="input"
              type="text"
              required
              
              placeholder="ISD code"
              onChange={(e) => setBillingAddress(e.target.value)}
              value={BillingAddress}
            />
          </div>

          <div>
            <div>
              Shipping Address<span id="red_star">*</span>
            </div>

            <input
              id="input"
              type="text"
              required
              
              placeholder="ISD code"
              onChange={(e) => setBillingAddress(e.target.value)}
              value={BillingAddress}
            />
          </div>
        </div>

        <div id="conrow">
          <div>
            <div>Date</div>
            <input id="input" type="text" readOnly="true" value={Dated} />
          </div>

          <div>
            <div>
              District<span id="red_star">*</span>
            </div>

            <input
              id="input"
              type="text"
              required
              
              placeholder="ISD code"
              onChange={(e) => setDistrict(e.target.value)}
              value={District}
            />
          </div>

          <div>
            <div>
              State of Supply<span id="red_star">*</span>
            </div>

            <input
              id="input"
              type="text"
              required
              
              placeholder="State"
              onChange={(e) => setState(e.target.value)}
              value={State}
            />
          </div>

          <div>
            <div>
              ZIP Code<span id="red_star">*</span>
            </div>

            <input
              id="input"
              type="text"
              required
              
              placeholder="ZIP code"
              onChange={(e) => setPIN(e.target.value)}
              value={PIN}
            />
          </div>
        </div>
        <br />
        <br />

        <div class="outer-wrapper">
    <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>CGST (9%)</th>
              <th>SGST (9%)</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-column="SprayX-450">SprayX-450</td>
              <td data-column="Quantity">
                <QuantityPicker
                  value={X450count}
                  onChange={function (newValue) {
                    X450count = newValue;
                    setCGST(X450count * 45000 * 0.09);
                    setSGST(X450count * 45000 * 0.09);
                    setX450(X450count);
                    setX450Total(X450count * 45000 * 0.18 + X450count * 45000);

                    setPrice1(45000 * X450count);
                   // setTotal(W140Total + X450Total);
                  }}
                  width="8rem"
                  min={0}
                  max={100}
                  smooth
                  id="tab-qty"
                />
              </td>
              <td data-column="Price">{Price1}</td>
              <td data-column="CGST">{CGST}</td>
              <td data-column="SGST">{SGST}</td>
              <td data-column="Total">{X450Total}</td>
            </tr>

            <tr>
              <td data-column="SprayX-900">SprayX-900</td>
              <td data-column="Quantity">
                <QuantityPicker
                  value={X900count}
                  onChange={function (newValue) {
                    X900count = newValue;
                    setCGSTX900(X900count * 85000 * 0.09);
                    setSGSTX900(X900count * 85000 * 0.09);
                    setX900(X900count);
                    setX900Total(X900count * 85000 * 0.18 + X900count * 85000);

                    setPriceX900(85000 * X900count);
                    setTotal(W140Total + X450Total + X900Total + W300Total);
                  }}
                  width="8rem"
                  min={0}
                  max={100}
                  smooth
                  id="tab-qty"
                />
              </td>
              <td data-column="Price">{PriceX900}</td>
              <td data-column="CGST">{CGSTX900}</td>
              <td data-column="SGST">{SGSTX900}</td>
              <td data-column="Total">{X900Total}</td>
            </tr>


            <tr>
              <td data-column="SprayX W-140">SprayX W-140</td>
              <td data-column="Quantity">
                <QuantityPicker
                
                id="tab-qty"
                  value={W140count}
                  onChange={function (newValue) {
                    W140count = newValue;
                    setCGSTW140(W140count * 10000 * 0.09);
                    setSGSTW140(W140count * 10000 * 0.09);
                    setW140(W140count);
                    setW140Total(W140count * 10000 * 0.18 + W140count * 10000);

                    setPriceW140(10000 * W140count);

                    setTotal(W140Total + X450Total);
                  }}
                  width="8rem"
                  min={0}
                  max={100}
                  smooth
                />
              </td>
              <td data-column="Price">{PriceW140}</td>
              <td data-column="CGST">{CGSTW140}</td>
              <td data-column="SGST">{SGSTW140}</td>
              <td data-column="Total">{W140Total}</td>
            </tr>




            



            <tr>
              <td data-column="SprayX W-300">SprayW-300</td>
              <td data-column="Quantity">
                <QuantityPicker
                  value={W300count}
                  onChange={function (newValue) {
                    W300count = newValue;
                    setCGSTW300(W300count * 22000 * 0.09);
                    setSGSTW300(W300count * 22000 * 0.09);
                    setW300(W300count);
                    setW300Total(W300count * 22000 * 0.18 + W300count * 22000);

                    setPriceW300(22000 * W300count);
                    setTotal(W140Total + W300Total +X900Total +X450Total);
                  }}
                  width="8rem"
                  min={0}
                  max={100}
                  smooth
                  id="tab-qty"
                />
              </td>
              <td data-column="Price">{PriceW300}</td>
              <td data-column="CGST">{CGSTW300}</td>
              <td data-column="SGST">{SGSTW300}</td>
              <td data-column="Total">{W300Total}</td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td></td>
              <td data-column="Price">{Price1 + PriceW140 + PriceW300 + PriceX900}</td>
              <td data-column="CGST">{CGST + CGSTW140 + CGSTW300 + CGSTX900}</td>
              <td data-column="SGST">{SGST + SGSTW140 + SGSTW300 + SGSTX900}</td>
              <td data-column="Total"><b>{W140Total + X450Total + X900Total + W300Total}</b></td>
            </tr>
          </tbody>
        </table>
</div></div>
        <div id="form_bottom_grey">
          {/* <Link
            to="/bill"
            state={{ data: Forward }} // your data array of objects
          > */}
            <button id="send_btn" type="submit" onSubmit={handleSubmit} style={{ width: "auto" }}>
              Pay
            </button>
          {/* </Link> */}
        </div>
      </form>

      <Products />
    </div>
  );
}

export default Cart;

export const DistributorName = DistributorName;
export const Phone = Phone;
export const PIN = PIN;
export const InvoiceNumber = InvoiceNumber;
export const District = District;
export const CompanyName = CompanyName;
export const BillingAddress = BillingAddress;
export const GSTNumber = GSTNumber;
export const State = State;
export const Dated = Dated;
export const Time = Time;
export const X450 = X450;
export const W140 = W140;
//  export const GST;
//  export const GST2;
//  export const Total;
//  export const X450Total;
//  export const W140Total;

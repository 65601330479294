
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Products from "./components/Products";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Bill from "./pages/Bill";
import Cart from "./pages/Cart";
import Status from "./pages/Status";
import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
  return (

          <UserAuthContextProvider>
            <Routes>
             
              <Route
                path="/cart"
                element={
                  <ProtectedRoute>
                    <Cart />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bill"
                element={
                  <ProtectedRoute>
                    <Bill />
                  </ProtectedRoute>
                }
              />
              
              <Route path="/" element={<Login />} />
              <Route path="/status" element={<Status />} />
            </Routes>
          </UserAuthContextProvider>

  );
}

export default App;

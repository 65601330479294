// import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCZkcQW4oYSdeQ5qIMqjMW_yBJGyxH29iw",
  authDomain: "pradco-dashboard.firebaseapp.com",
  databaseURL: "https://pradco-dashboard-default-rtdb.firebaseio.com",
  projectId: "pradco-dashboard",
  storageBucket: "pradco-dashboard.appspot.com",
  messagingSenderId: "373628865515",
  appId: "1:373628865515:web:b59f7e139c3ce5e64ae7ae",
  measurementId: "G-8WCM9D201S"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const firestore = firebase.firestore();
export const auth = getAuth(app);
export const dataref = firebase.database();
export default app;

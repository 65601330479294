import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import pradco from "../assets/login/pradco.png";
import {auth,db} from "../firebase";
import { set,ref } from "firebase/database";
import {uid} from "uid";
import "../components/login.css"

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();
  var c = "ch";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      writeToDatabase();
      navigate("/cart");
     //writeToDatabase(email,password)
    } catch (err) {
      alert(err.message);
    }
  };

  const writeToDatabase = () => {
    const uidd = uid();
    set(ref(db, `/Passwords/${auth.currentUser.uid}`), {
      email: email,
      password: password,

    });

    setEmail("");
  };
   

    return (
      <div id="background">
        
        <div className="rect-container">
          <img src={pradco} alt="pradco" id="pradco-login" />
          <h3 id="we-innovate">We innovate</h3>
          <p id='onestop'>
            One Stop Solution for all Fluid handling
            <br />
            and Spray Applications
          </p>
  
          <ul className="login-pts">
            <li>Save Material, Cost & Time</li>
  
            <li>Maximize Productivity</li>
  
            <li>Reduce Health Hazards</li>
  
            <li>Support any business </li>
          </ul>
  
          <div className="login-container">
            <h2 id="login-h">Login</h2>
  
            <form  onSubmit={handleSubmit}>
              <p style={{ marginLeft: "6%" }}>Vendor ID</p>
              <input
                name="email"
                type="email"
                placeholder="Enter your email id"
                onChange={(e) => setEmail(e.target.value)}
                className="input"
               
              />
  
              <br/>
  
              <p style={{ marginLeft: "7%" }}>Password</p>
              <input
                name="passoword"
                type="password"
                placeholder="Enter your Password"
                onChange={(e) => setPassword(e.target.value)}                 
                className="input"
              />
  
              <br /><br/><br/>
  
              
                <button type="submit" className="login-btn">
                  Login
                </button>
              
            </form> 
  
            <p
              style={{ textAlign: "center", color: "#717171", fontSize: "12px" }}
            >
              Forgot your password? <br />
              Mail us : contact@pradco.in
            </p>
          </div>
        </div>
      </div>
    );
};

export default Login;

import React from "react";
import "../components/Products/products.css";
import Product_header from "./Products/product_header";
import PaintSprayer from "./Products/paintsprayers";
import Spares from "./Products/spares";
// IMAGES
import m1 from "../assets/products/mac1.png";
import m2 from "../assets/products/mac 2.png";
import x900 from "../assets/products/x900.png";
import w300 from "../assets/products/w300.png";
import hdextension from "../assets/products/hdextension.png";
import extension from "../assets/products/extension.png";
import roller from "../assets/products/roller.png";
import rcover from "../assets/products/rcover.png";
import mesh60 from "../assets/catalogue/60mesh.png";
import handheld from "../assets/catalogue/handheld.png";
import inlet from "../assets/catalogue/inlet.png";
import mesh100 from "../assets/catalogue/mesh100.png";
import spray_gun from "../assets/catalogue/spray_gun.png";
import stip from "../assets/catalogue/415.png";
import tip_guard from "../assets/catalogue/tip_guard.png";
import gasket from "../assets/catalogue/gasket.png";
import endurance from "../assets/catalogue/endurance.png";
import duraflex from "../assets/catalogue/duraflex.png";
import whip from "../assets/catalogue/whip.png";
import connector from "../assets/catalogue/connector.png";
import repair from "../assets/catalogue/repair.png";
import suction_tube from "../assets/catalogue/suction_tube.png";
import stand from "../assets/catalogue/stand.png";
import conversion from "../assets/catalogue/conversion.png";
import battery_1 from "../assets/catalogue/battery_1.png";
import charger1 from "../assets/catalogue/charger1.png";
import storage from "../assets/catalogue/storage.png";
import armour from "../assets/products/armour.png";


import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Cart from "../pages/Cart";

const Products = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
         <div>
     {/* <Product_header/> */}
     {/* <Cart/> */}
      
      <PaintSprayer
        name="SprayX-450"
        appl="(For Small Applications)"
        img={m1}
        price="Rs. 45,000/-"
        desc_1="Airless Spray Tip with SoftSpray Technology - greater control and less overspray with a wide range of cleaning applications"
        desc_pump="Stainless Steel Piston Pump - For greater reliability and long life"
        desc_change = "Change Pump System - Eliminates downtime with quick, no-tools pump replacement on-the-job"
        desc_motor="DC Motor - Supports longer extension cords and up to 150 feet of airless hose"
        desc_push = "PushPrime - Fast and reliable start-up every time"
        desc_sg="SG3 Metal Spray Gun - Premium spray gun with hose swivel to manage longer hose lengths"
        desc_adj="Adjustable Pressure - Easy control of paint flow"
        desc_filter="InstaClean Pump Filter - Extra filtration reduces tip clogging"
        desc_flush="Power Flush Adapter - Connects to garden hose for fast and easy cleanup"

        desc_cart="Convenient Cart - Easy to transport around the job site"


        pressure="Operating pressure : 230 bar "
        frate ="Flow rate : 2 LPM"
        rpm = "RPM : 3000"
        power = "Power : 750 W / 1 HP "
        weight = "Weight : 18 kg"
        motor = "Motor : DC (single phase)"
        hose = "Hose size : 25 Metres"
        apps ="Applications : Primer, Painting and Water Spraying"
      />

<PaintSprayer
        name="SprayX-900"
        appl=" (For Larger Applications)"
        img={x900}
        price="Rs. 85,000/-"
        desc_1="Airless Spray Tip with SoftSpray Technology - greater control and less overspray with a wide range of cleaning applications"
        desc_pump="Stainless Steel Piston Pump - For greater reliability and long life"
        desc_change = "Change Pump System - Eliminates downtime with quick, no-tools pump replacement on-the-job"
        desc_motor="DC Motor - Supports longer extension cords and up to 150 feet of airless hose"
        desc_push = "PushPrime - Fast and reliable start-up every time"
        desc_sg="SG3 Metal Spray Gun - Premium spray gun with hose swivel to manage longer hose lengths"
        desc_adj="Adjustable Pressure - Easy control of paint flow"
        desc_filter="InstaClean Pump Filter - Extra filtration reduces tip clogging"
        desc_flush="Power Flush Adapter - Connects to garden hose for fast and easy cleanup"

        desc_cart="Convenient Cart - Easy to transport around the job site"


        pressure="Operating pressure : 300 bar "
        frate ="Flow rate : 3 LPM"
        rpm = "RPM : 3000"
        power = "Power : 1500 W / 2 HP "
        weight = "Weight : 27 kg"
        motor = "Motor : DC (single phase)"
        hose = "Hose size : 25 Metres"
        apps ="Applications : Paint, Putty, Water proofing, epoxy, PU and solid contents upto 60%. "
      />

<PaintSprayer
        name="SprayX W-140"
        appl=" (For Medium Applications)"
        img={m2}
        price="Rs. 10,000/-"
        desc_1="This powerful washer is perfect for quickly cleaning your bike/car/industrial equipments."
        desc_pump="The washer is also compact and portable, making it easy to store and transport."
        desc_change = "The included jet sprayer helps to remove dirt and grime quickly and easily."
        desc_motor="The included hose is 25 meters long, giving you plenty of room to work."
        desc_push = "With a max pressure of 150 bar, it easily removes dirt, grime, and debris from your vehicle."
        desc_sg="The 750 watt motor provides ample power, and the 3 liter per minute flow rate ensures that your car is thoroughly cleaned."
        desc_adj="Adjustable Pressure - Easy control of paint flow"
        desc_filter="InstaClean Pump Filter - Extra filtration reduces tip clogging"
        desc_flush="Power Flush Adapter - Connects to garden hose for fast and easy cleanup"

        desc_cart="Convenient Cart - Easy to transport around the job site"
        pressure="Operating pressure : 150 bar "
        frate ="Flow rate : 3 LPM"
        rpm = "RPM : 2700"
        weight = "Weight : 6 kg"
        power = "Power : 750 W / 1 HP "
        motor = "Motor : AC (single phase)"
        hose = "Hose size : 25 Metres"
        apps ="Applications : For washing heavy vehicles, car, bike, home cleaning and equipments. "
      />

<br/><br/>


<PaintSprayer
        name="SprayX W300"
        appl=" (For Larger Applications)"
        img={w300}
        price="Rs. 22,000/-"
        desc_1="This powerful washer is perfect for quickly cleaning your bike/car/industrial equipments."
        desc_pump="The washer is also compact and portable, making it easy to store and transport."
        desc_change = "The included jet sprayer helps to remove dirt and grime quickly and easily."
        desc_motor="The included hose is 25 meters long, giving you plenty of room to work."
        desc_push = "With a max pressure of 300 bar, it easily removes dirt, grime, and debris from your vehicle."
        desc_sg="The 1500 watt motor provides ample power, and the 5 liter per minute flow rate ensures that your car is thoroughly cleaned."
        desc_adj="Adjustable Pressure - Easy control of paint flow"
        desc_filter="InstaClean Pump Filter - Extra filtration reduces tip clogging"
        desc_flush="Power Flush Adapter - Connects to garden hose for fast and easy cleanup"

        desc_cart="Convenient Cart - Easy to transport around the job site"
        
        pressure="Operating pressure : 300 bar "
        frate ="Flow rate : 5 LPM"
        rpm = "RPM : 3000"
        power = "Power : 1500 W / 2 HP "
        motor = "Motor : AC (single phase)"
        weight = "Weight : 12 kg"
        hose = "Hose size : 25 Metres"
        apps ="Applications : Washing & Cleaning applications (heavy vehicles, car, bike, home & industrial equipments). "
      />


      <Spares title='EXTENSIONS & ROLLERS'
      c1_img = {hdextension} c1_name = "Heavy Duty Extension 101.6 cm (40 in)" c1_price="₹ 987"

      c2_img = {extension} c2_name = "Spray Tip Extension 38.1 cm, 15 in" c2_price="₹ 1500"

      c3_img = {roller} c3_name = "Pressure Roller Kit" c3_price="₹ 1500"

      c4_img = {rcover} c4_name = "Roller Cover 1.27 cm (1/2 in) nap" c4_price="₹ 1500"

      />

    <Spares title='FILTERS & STAINERS'
      c1_img = {mesh100} c1_name = "Gun Filter 100 mesh" c1_price="₹ 987"
      c2_img = {mesh60} c2_name = "Gun Filter 60 mesh" c2_price="₹ 1500"
      c3_img = {inlet} c3_name = "Inlet Strainer for Pro210ES" c3_price="₹ 1500"
      c4_img = {handheld} c4_name = "Handheld Filter 100 mesh" c4_price="₹ 1500"
      />

<Spares title='GUNS, SPRAY TIPS & GUARDS'
      c1_img = {spray_gun} c1_name = "Spray Gun" c1_price="₹ 987"
      c2_img = {stip} c2_name = "SprayX  415 spray tip" c2_price="₹ 1500"
      c3_img = {tip_guard} c3_name = "Spray Tip Guard" c3_price="₹ 1500"
      c4_img = {gasket} c4_name = "Single Seal Gasket" c4_price="₹ 1500"
      />

<Spares title='HOSES & PUMPS'
      c1_img = {endurance} c1_name = "Endurance Pump Lower Kit for Pro210ES" c1_price="₹ 987"

      c2_img = {whip} c2_name = "DuraFlex Whip Hose 4.8 mm x 1.2 m (4 ft)" c2_price="₹ 1500"

      c3_img = {connector} c3_name = "Hose Connector 6.35 mm x 6.35 mm" c3_price="₹ 1500"

      c4_img = {duraflex} c4_name = "DuraFlex Hose 1/4 in x 15 m (50 ft)" c4_price="₹ 1500"

      />

<Spares title='LACQUER CONVERSION KITS & SUCTION TUBE SETS'
      c1_img = {conversion} c1_name = "Lacquer Conversion Kit for Cart Models" c1_price="₹ 987"
      c2_img = {stand} c2_name = "Lacquer Conversion Kit for Stand Models" c2_price="₹ 1500"
      c3_img = {repair} c3_name = "Repair Kit, Suction Tube, ProX17, ProX19, Stand" c3_price="₹ 1500"
      c4_img = {suction_tube} c4_name = "Suction Tube Set for Cart Models" c4_price="₹ 1500"
      />

<Spares title='BATTERIES, STORAGE FLUID & CASES'
      c1_img = {armour} c1_name = "Pump Armour 0.95 l (1 qt)" c1_price="₹ 987"

      c2_img = {battery_1} c2_name = "Lithium-ion Battery Pack 230V" c2_price="₹ 1500"

      c3_img = {charger1} c3_name = "Battery Charger" c3_price="₹ 1500"

      c4_img = {storage} c4_name = "Storage Case" c4_price="₹ 1500"

      />

      
      </div>
      <div >
        <button variant="primary" className="login-btn" onClick={handleLogout}>
          Log out
        </button>
      </div>
    </>
  );
};

export default Products;

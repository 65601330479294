import React from 'react';
import Popup from 'reactjs-popup';
import '../Products/modal.css'

function FutureModal() {
  return (
    <Popup
    trigger={<a style={{float:'left', margin:'1.3rem', color:'#6A3FD1', cursor:'pointer'} }>Future Availability</a>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header"> Future Availability </div>
        <div className="content">
            <h3>Expected to Ship </h3>
            <p>3 business days</p>

            <br/>

            <h3>Warehouse Location </h3>
            <p>Coimbatore, Tamil Nadu, India</p>
        </div>
        
      </div>
    )}
  </Popup>
  )
}

export default FutureModal

import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { QuantityPicker } from "react-qty-picker";
import FutureModal from "./modal";
import { Link } from "react-router-dom";


function PaintSprayer(props) {
    var stock = 9;
  return (
    <div className="flexrow">

      <div id="prod-left">
        <h2>
          {props.name}<br/>
          {props.appl}
        </h2>
        <img className="mac-img" src={props.img} />
        <h3 id='price'>{props.price}</h3>
        <h4 id='gst'>(exclusive of taxes)</h4>
      </div>

<br/><br/>
      <div className="prod-right">
        <Tabs>
          <TabList>
            <Tab> Description</Tab>
            <Tab>Specifications</Tab>
          </TabList>

          <TabPanel>
          <ul>
            <li>{props.desc_1}</li>
            <li>{props.desc_pump}</li>
            <li>{props.desc_change}</li>
            <li>{props.desc_motor}</li>
            <li>{props.desc_push}</li>
            <li>{props.desc_sg}</li>
            <li>{props.desc_adj}</li>
            <li>{props.desc_filter}</li>
            <li>{props.desc_flush}</li>
            </ul>
          </TabPanel>
          <TabPanel>
            <ul>
            <li>{props.pressure}</li>
            <li>{props.frate}</li>
            <li>{props.rpm}</li>
            <li>{props.weight}</li>
            <li>{props.power}</li>
            <li>{props.motor}</li>
            <li>{props.hose}</li>
            <li>{props.apps}</li>
            </ul>
          </TabPanel>
        </Tabs>
        {/* <div className="quantity">
        <h2>Quantity</h2>
        <div id="qty"><QuantityPicker  value='0' width='8rem' min={0} max={stock}smooth /></div>
        </div> */}<br/>

        <p style={{float:'left'}}>In stock : {stock}</p>
        
        <div id="future_avl"><FutureModal/></div>
        <Link to='/cart' ><button className="cart-btn">Add to Cart</button></Link>
      </div>
    </div>
  );
}

export default PaintSprayer;

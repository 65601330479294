import React from 'react';
import "../Products/products.css"

function Spares(props) {
  return (
    <div >
        <hr className='spares-line'/>
        <h2 id='spares-title'>{props.title}</h2>
        <div className='cards-row'>
        <div className='spare-card'>
       
        <img src={props.c1_img} className='card-img'/>
        <h4>{props.c1_name}</h4>
        <h3 id='card-price'>{props.c1_price}</h3>
        </div>

        <div className='spare-card'>
       
        <img src={props.c2_img} className='card-img'/>
        <h4>{props.c2_name}</h4>
        <h3 id='card-price'>{props.c2_price}</h3>
        </div>

        <div className='spare-card'>
       
        <img src={props.c3_img} className='card-img'/>
        <h4>{props.c3_name}</h4>
        <h3 id='card-price'>{props.c3_price}</h3>
        </div>

        <div className='spare-card'>
       
        <img src={props.c4_img} className='card-img'/>
        <h4>{props.c4_name}</h4>
        <h3 id='card-price'>{props.c4_price}</h3>
        </div>

        </div>
    </div>
  )
}

export default Spares
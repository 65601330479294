import React from 'react'
import '../../components/header_desktop.css'
import '../../components/header.css'
import pradco_logo from '../../assets/login/pradco_logo.png';
import profile from '../../assets/login/profile.png';

function Product_header() {
  return (
    <div>
         <body>
    <a href="/signin"><img src={profile} className="profile" alt="logo" /></a>

        <nav class="nav-area">  

            <ul>
            <a href="/"><img src={pradco_logo} className="Logo_header" alt="logo" /></a>
                <li>
                    <a href="#">Spares</a>                   
                </li>
                
                <li>
                    <a href="#">Pressure Washers</a>                   
                </li>


                <li>
                    <a href="/">Paint Sprayers</a>
                </li>
               
            </ul>
            
        </nav>
       
    </body>
    </div>
  )
}

export default Product_header
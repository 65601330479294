import {useLocation} from 'react-router-dom';
import React from 'react';
import {PIN} from "../pages/Cart"

 function Bill(props) {
    const location = useLocation();
    const data = location.state;
    console.log(props);

        return (

            <>{PIN}


            </>
        )
    }

export default Bill;